import React, { useEffect, useState } from 'react'
import { ADMIN_ANALYSIS_DATA, PROJECT_DATA_ANALYSIS, SURVEY_ANALYSIS_DATA } from './modules'
import AnalysisTiles from '../Dashboard/AnalysisTiles'
import { DataTable } from '../Projects/AllProjects/Table/components/data-table'
import { columns } from '../Projects/AllProjects/Table/components/columns'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { getAdminDashboardProjectsSummaryRequestApi, getAdminDashboardSummaryRequestApi, getSalesExecutiveDashboardSummaryRequestApi } from 'src/services/requests/Dashboard'
import { IAdminDashboardSummaryResponse, IEvaluatorDashboardSummaryResponse } from 'src/services/requests/Dashboard/modules'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'

type Props = {}

const AdminDashboardSummary = (props: Props) => {

  const [data, setdata] = useState(ADMIN_ANALYSIS_DATA)
  const [projectData, setprojectData] = useState(PROJECT_DATA_ANALYSIS)
  const [Surveydata, setSurveydata] = useState(SURVEY_ANALYSIS_DATA)
  const navigate = useNavigate()

  useEffect(() => {
    handleFetchSummaryDetails()
    handleFetchSalesExecutiveSummary()
    handleProjectSummaryDetails()
    return () => { }
  }, [])

  const handleFetchSummaryDetails = catchAsync(async () => await getAdminDashboardSummaryRequestApi(), (result: IAdminDashboardSummaryResponse) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setdata(data.map((d: any) => {
        return {
          ...d,
          value: result.data[d.dataKey as keyof object] || 0
        }
      }))
    }
  }, () => { })

  const handleProjectSummaryDetails = catchAsync(async () => await getAdminDashboardProjectsSummaryRequestApi(), (result: any) => {
    if (result.statsu == 200) {
      setprojectData([...projectData.map((d: any) => {
        return {
          ...d,
          value: result.data[d.dataKey as keyof object] || d.value
        }
      })])
    }
  }, () => { })

  const handleFetchSalesExecutiveSummary = catchAsync(async () => await getSalesExecutiveDashboardSummaryRequestApi(), (result: IAdminDashboardSummaryResponse) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setSurveydata(Surveydata.map((d: any) => {
        return {
          ...d,
          value: result.data[d.dataKey as keyof object] || 0
        }
      }))
    }
  }, () => { })


  return (
    <div className=''>
      <div className='my-2 flex justify-between'>
        <div className='font-semibold text-primary uppercase'>Reports Summary</div>
        <button onClick={() => navigate("/" + APP_ROUTES.sales_executive.subRoutes.reports.subRoutes.all.fullRoute)} className='underline underline-offset-2 text-gray-500 hover:text-primary text-sm cursor-pointer mx-4'>View More</button>
      </div>
      <div className={`grid gap-4 md:grid-cols-3 lg:grid-cols-3`}>
        {
          Surveydata.map((d: any) => {
            return <AnalysisTiles {...d} />
          })
        }
      </div>
      <div className={`mt-4 grid gap-4 md:grid-cols-3 lg:grid-cols-3`}>
        {
          data.map((d: any) => {
            return <AnalysisTiles {...d} />
          })
        }
      </div>
      <div className={`mt-4 grid gap-4 md:grid-cols-3 lg:grid-cols-3`}>
        {
          projectData.map((d: any) => {
            return <AnalysisTiles {...d} />
          })
        }
      </div>

    </div>
  )
}

export default AdminDashboardSummary