import { FileCheck2, FileClock, FileCog, FileMinus2, Files, FileSearch, Siren } from "lucide-react";

export const ADMIN_ANALYSIS_DATA = [
  {
    title: "Total Pending For Approval",
    value: "0",
    dataKey: "totalPendingApproval",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Total Pending For Financial Eligibility",
    value: "0",
    dataKey: "totalPendingEligibility",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Executed",
    value: "0",
    dataKey: "totalprojects",
    prefix: "#",
    icon: <FileCog size={24} className='text-gray-500' />
  },
  {
    title: "Total Rejected ",
    value: "0",
    dataKey: "totalRejected",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Enquiry Raised By Offtaker",
    value: "0",
    dataKey: "totalResuestsByOfftaker",
    prefix: "#",
    icon: <FileSearch size={24} className='text-gray-500' />
  },
  {
    title: "Total Enquiry",
    value: "0",
    dataKey: "totalEnquiries",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
]


export const EPC_CONTRACTOR_ANALYSIS_DATA = [
  {
    title: "MTD-Project Requests",
    value: "0",
    dataKey: "monthlyCount",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Request of Day",
    value: "0",
    dataKey: "dailyCount",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Total Approved Projects",
    value: "0",
    dataKey: "approvedCount",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },
  {
    title: "Total Pending For Approval",
    value: "0",
    dataKey: "totalPendingApproval",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Total Pending For Financial Eligibility",
    value: "0",
    dataKey: "totalPendingEligibility",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Executed",
    value: "0",
    dataKey: "totalprojects",
    prefix: "#",
    icon: <FileCog size={24} className='text-gray-500' />
  },
  {
    title: "Total Rejected ",
    value: "0",
    dataKey: "totalRejected",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Size (kW)",
    value: "0",
    dataKey: "projectSize",
    prefix: "#",
    icon: <FileCog size={24} className='text-gray-500' />
  },
]


export const PROJECT_DATA_ANALYSIS = [
  {
    title: "Total Project Size (kW)",
    value: "0",
    dataKey: "projectSize",
    prefix: "#",
    icon: <FileCog size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Cost (INR)",
    value: "0",
    dataKey: "projectCount",
    prefix: "₹",
    icon: <FileCog size={24} className='text-gray-500' />
  },
  {
    title: "Total Amount Invested",
    value: "0",
    dataKey: "investmentCount",
    prefix: "₹",
    icon: <FileCog size={24} className='text-gray-500' />
  },
]



export const EVALUATOR_ANALYSIS_DATA = [
  {
    title: "Total Projects",
    value: "0",
    dataKey: "totalprojects",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Verified",
    value: "0",
    dataKey: "totalVerifiedProjects",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },
  {
    title: "Un-Verified",
    value: "0",
    dataKey: "totalUnVerifiedProjects",
    prefix: "#",
    icon: <FileMinus2 size={24} className='text-gray-500' />
  },
  {
    title: "Pending",
    value: "0",
    dataKey: "totalPendingProjects",
    prefix: "#",
    icon: <FileClock size={24} className='text-gray-500' />
  },
]

export const SURVEY_ANALYSIS_DATA = [
  {
    title: "MTD-Project Requests",
    value: "0",
    dataKey: "monthlyCount",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Request of Day",
    value: "0",
    dataKey: "dailyCount",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Total Approved Projects",
    value: "0",
    dataKey: "approvedCount",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },

]

export const STAKEHOLDER_ANALYSIS_DATA = [
  {
    title: "Total Projects",
    value: "0",
    dataKey: "totalprojects",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Approved Projects",
    value: "0",
    dataKey: "totalApprovedProjects",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },
  {
    title: "Reject Projects",
    value: "0",
    dataKey: "totalRejectedProjects",
    prefix: "#",
    icon: <FileMinus2 size={24} className='text-gray-500' />
  },
  {
    title: "Pending Projects",
    value: "0",
    dataKey: "totalPendingProjects",
    prefix: "#",
    icon: <FileClock size={24} className='text-gray-500' />
  },
]