import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE, ROLES, successTheme } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import EPCProfileForm from 'src/modules/EpcContractors/EpcProfile/EPCProfileForm'
import { INIT_EPC_CONTRACTOR_PROFILE } from 'src/modules/EpcContractors/EpcProfile/modules'
import ServicesFrm from 'src/modules/Manufacturer/ManufacturerProfile/ServicesFrm'
import ManufacturerProfileForm from 'src/modules/Manufacturer/ManufacturerProfile/ManufacturerProfileForm'
import { EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import { epcCreateProfileApiRequest, epcViewProfileByIDApiRequest } from 'src/services/requests/EPC/profile'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Separator, Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { createUserRequestApi, registrationRequestApi } from 'src/services/requests/Auth'
import { createManufacturerProfileApiRequest } from 'src/services/requests/Manufacturers'
import { toast } from 'sonner'

type Props = {}

const ManufacturerCreateMain = (props: Props) => {

    const [isLoading, setisLoading] = useState<boolean>(false)
    const [initFormDate, setinitFormDate] = useState(INIT_EPC_CONTRACTOR_PROFILE)
    const [resultData, setresultData] = useState<any>()
    const [allServices, setallServices] = useState<any>([])
    const [currentActiveTab, setcurrentActiveTab] = useState<string>("complete_profile")
    const navigate = useNavigate()
    const { currentUser } = useAuth()

    // useEffect(() => {
    //     if (currentUser?.email) {
    //         setinitFormDate({
    //             ...initFormDate,
    //             contractorEmail: currentUser?.email
    //         })
    //     }

    //     return () => { }
    // }, [])

    const handleCreateUser = catchAsync(async (formdata: any) => {
        setisLoading(true)
        return await createUserRequestApi({
            email: formdata?.manufacturerEmail,
            role: ROLES.MANUFACTURER
        })
    }, (result: any, formdata: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            console.log({ formdata });
            handleCreateProfile({ ...formdata, manufacturer_id: result?._id })
            setresultData({
                ...resultData,
                epc_id: result?._id,
            })
        }
        setisLoading(false)
    }, () => {  setisLoading(false) })

    const handleCreateProfile = catchAsync(async (formdata: any) => {
        setisLoading(true)
        console.log({ formdata });
        return formdata && await createManufacturerProfileApiRequest({
            ...formdata,
            products: [],
            partnerType: "-",
            product_manufacturer: "-",
            manufacturerLogo: "-"
            // epc_contractor_id: currentUser?.id
        })
    }, (result: any) => {
        console.log({ result });
        if (IS_SUCCESS_RESPONSE(result)) {
            setresultData({
                ...resultData,
                _id: result?._id,
            })
            setcurrentActiveTab("add_services")
            toast.success("Profile created.", { style: successTheme })
            // navigate("/" + EPC_CONTRACTORS_ROUTES.dashboard.fullRoute)
        }
        setisLoading(false)
    }, () => {
        setisLoading(false)
    })

    console.log({ initFormDate });

    return (
        <div className='p-4'>
            <ScrollArea className='h-[90vh]'>

                <Tabs value={currentActiveTab} onValueChange={(e: any) => setcurrentActiveTab(e)} className="">
                    <TabsList>
                        <TabsTrigger disabled={resultData?._id && resultData?.epc_id} value="complete_profile">Profile</TabsTrigger>
                        <TabsTrigger
                            disabled={!resultData?._id && !resultData?.epc_id}
                            value="add_services">Products</TabsTrigger>
                    </TabsList>
                    <TabsContent value="complete_profile">
                        <ManufacturerProfileForm disableForm={resultData?._id} initial_values={initFormDate} submitBtnTitle='Save' onSubmit={(formdata: any) => handleCreateUser(formdata)} />
                    </TabsContent>
                    <TabsContent value="add_services">
                        <ServicesFrm id={resultData?._id} epc_id={resultData?.epc_id} />
                    </TabsContent>
                </Tabs>

                <Separator />
                {/* <div className='uppercase text-md font-bold text-primary mt-4 '>Services </div> */}

                {/* <div className='flex justify-center space-x-2 my-2'>
                    <RoundedButton type='button' disabled={false} variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
                    <RoundedButton onClick={() => navigate("/" + EPC_CONTRACTORS_ROUTES.profile.subRoutes.view.fullRoute + "/" + currentUser?.id)} >Save</RoundedButton>
                </div> */}
            </ScrollArea>
            <LoadingDialog isOpen={isLoading} message="Profile updating..." />
        </div>
    )
}

export default ManufacturerCreateMain