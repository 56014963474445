import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { catchAsync, EXTRACT_NUMBERS_REGEX, FormField, IS_SUCCESS_RESPONSE, NUMBER_REGEX } from 'src/helpers';
import { PROJECT_FORM_FIELDS, PROJECT_FORM_INITIAL_VALUES } from 'src/modules/Projects/modules';
import ProjectForm from 'src/modules/Projects/ProjectForm';
import { APP_ROUTES } from 'src/navigations/modules';
import { createProjectApiRequest, generateAgreementApiRequest } from 'src/services/requests/Projects';
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog';

type Props = {}

const CreateProjectMain = (props: Props) => {

  const navigate = useNavigate()

  const [projectData, setprojectData] = useState(PROJECT_FORM_INITIAL_VALUES)
  const [isLoading, setisLoading] = useState(false)


  useEffect(() => {
    getMyLocation()
    return () => { }
  }, [])



  const getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition((position) => {
        // this.setState()

        console.log({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        setprojectData({
          ...PROJECT_FORM_INITIAL_VALUES,
          geoGraphicalLocation: `${position.coords.latitude},${position.coords.longitude}`
        })
      }, (error) => {
        // this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }

  }


  const handle_create_project = catchAsync(
    async (formData: any) => {
      console.log({ formData });
      setisLoading(true)
      let postData: any = {};
      Object.entries(formData).forEach(([key, value]) => {
        let filed: FormField | undefined = PROJECT_FORM_FIELDS.find((d: FormField) => d.dataKey == key)
        if (filed && filed.componentProps?.numberFormatted) {
          postData[key] =value && value.toString().replace(EXTRACT_NUMBERS_REGEX, "")
        }
        else postData[key] = value
      });
      return formData && createProjectApiRequest(postData)
    },
    async (result: AxiosResponse) => {
      if (IS_SUCCESS_RESPONSE(result)) {
        setprojectData(PROJECT_FORM_INITIAL_VALUES)
        navigate("/" + APP_ROUTES.projects.subRoutes.all.fullRoute)
      }
      setisLoading(false)
    }
  );


  return (
    <div>
      <div className='space-y-4'>
        <ProjectForm initial_values={projectData} isLoadingForm={false} onSubmit={(formData) => {
          // handle_create_project(formData)
          // handleGenerateAgreement()
          handle_create_project(formData)
        }} submitBtnTitle={"Create Project"} />
      </div>
      <LoadingDialog isOpen={isLoading} message='Creating Project' /> 
    </div>
  )
}

export default CreateProjectMain