import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
// import { LayoutSplashScreen } from '../../../Helpers'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { getUserByToken } from "src/services/services";
import { WithChildren } from "../../../helpers";
import Splashscreen from "../Splashscreen";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  // console.log({ auth });

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {

  const { auth, logout, setCurrentUser, currentUser, saveAuth } = useAuth();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  // console.log("Auth Check", currentUser);

  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        // if (!didRequest.current) {
        //   const data: any =
        //     auth?.user_id &&
        //     (await getUserByToken());

        //   // console.log({ data:data?.data });
        //   if (data && data?.data) {
        //     data && setCurrentUser(data?.data);
        //     dispatch(setUser(data?.data))
        //     dispatch(setIsNewUser(data?.newuser))
        //     dispatch(setIsVerified(data?.kyc_status))
            
        //   } else {
        //     logout();
        //   }
        // }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }
      return () => (didRequest.current = true);
    };

    if (auth && auth.api_token) {
      requestUser(auth.api_token);
    } else {
      // console.log("tring to get user");
      // logout()
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <Splashscreen /> : <>{children}</>;
};

export { AuthInit, AuthProvider, useAuth };

