import { CaretSortIcon } from '@radix-ui/react-icons'
import { File, Home, LogOut, Package, PackagePlus, User, LayoutGrid } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { catchAsync, cn, IS_SUCCESS_RESPONSE, ROLES } from 'src/helpers'
import { APP_ROUTES, EPC_CONTRACTORS_ROUTES, MANUFACTURER_ROUTES } from 'src/navigations/modules'
import { Button, Collapsible, CollapsibleContent, CollapsibleTrigger, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger, ResizableHandle, ResizablePanel, ResizablePanelGroup, Separator, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'src/shadcn/components/ui'
import { LayoutMainContextProvider, useLayoutMainContext } from '../LayoutContext/MainContext'
import Nav, { link } from './Nav'
import { useAuth } from 'src/modules/Auth/Core'
import ProfileComp from './ProfileComp'
import { epcViewProfileByIDApiRequest } from 'src/services/requests/EPC/profile'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'

type Props = {}

type ITabs = {
    title: string,
    icons: any
    path: string,
    active_paths: string
    roles?: string[] | undefined,
    subTabs?: ITabs[] | undefined
}


const TABS: ITabs[] = [
    {
        title: "Dashboard",
        icons: <Home className="h-4 w-4" />,
        path: APP_ROUTES.dashboard.route,
        active_paths: APP_ROUTES.dashboard.route,
        roles: undefined,
        subTabs: undefined
    },
    {
        title: "Projects",
        icons: <Package className="h-4 w-4" />,
        path: APP_ROUTES.projects.route + "/",
        active_paths: APP_ROUTES.projects.route + "/",
        roles: undefined,
        subTabs: [
            {
                title: "Create Project",
                icons: <PackagePlus className="h-4 w-4" />,
                path: APP_ROUTES.projects.subRoutes.create.fullRoute,
                active_paths: APP_ROUTES.projects.subRoutes.create.fullRoute,
                roles: undefined,
                subTabs: undefined
            },
            {

                title: "Projects",
                icons: <Package className="h-4 w-4" />,
                path: APP_ROUTES.projects.subRoutes.all.fullRoute,
                active_paths: APP_ROUTES.projects.subRoutes.all.fullRoute,
                roles: undefined,
                subTabs: undefined
            }

        ]
    }
]

const RenderTab = ({ title, icons, path, roles, active_paths, subTabs }: ITabs) => {
    const { pathname } = useLocation()
    console.log({ active_paths });

    return subTabs && subTabs.length > 0 ?
        <Collapsible>
            <CollapsibleTrigger>
                <div className={cn("flex justify-between items-center w-100",)} >
                    <span className=' flex items-center gap-3 px-3 py-2 text-muted-foreground transition-all hover:text-primary rounded-lg ' > {icons}{title} </span>
                    <span><CaretSortIcon className="h-4 w-4" /></span>
                </div></CollapsibleTrigger>
            <CollapsibleContent>
                <div className='flex flex-col pl-[24px]'>
                    {
                        subTabs && subTabs.map((r: any) => {
                            return <RenderTab {...r} />
                        })
                    }
                </div>
            </CollapsibleContent>
        </Collapsible>
        :
        <Link
            to={path}
            className={cn("flex items-center gap-3 px-3 py-2 text-muted-foreground transition-all hover:text-primary rounded-lg", { " bg-primary text-white hover:text-white  ": "/" + active_paths == pathname })}
        >
            {icons}
            {title}
        </Link>

}

const getRoute = (routes: any, pathname: any) => {
    let routeObj = undefined;

    const checkRoute = (route: any) => {
        if (route.routeType === "dynamic" && new RegExp("/" + route.fullRoute.replace(/\//g, '\\/')).test(pathname)) {
            routeObj = route;
        } else if ("/" + route.fullRoute === pathname) {
            routeObj = route;
        }

        if (route.subRoutes && Object.values(route.subRoutes).length > 0) {
            Object.values(route.subRoutes).forEach((subRoute: any) => {
                checkRoute(subRoute);
            });
        }
    };

    Object.values(routes).forEach((route: any) => {
        checkRoute(route);
    });

    return routeObj;
};

const ReanderBreadcrumb = () => {

    let { pathname } = useLocation()
    let { currentUser } = useAuth()

    pathname = pathname

    const [path, title] = useMemo(() => {
        let routes: any = null

        if (currentUser?.role === ROLES.ADMIN_USER) {
            routes = APP_ROUTES
        }
        else if (currentUser?.role === ROLES.EPC_CONTRACTOR) {
            routes = EPC_CONTRACTORS_ROUTES
        }

        let path = ""
        let title = ""
        let routeObj: any = getRoute(routes, pathname)

        if (routeObj && Object.values(routeObj)?.length > 0) {
            const routeData: any = routeObj
            if (routeData.routeType == "dynamic") {
                const splitRoute = pathname.split("/" + routeData.fullRoute)
                title = routeData?.breadcrumbTitle
                path = routeData?.breadcrumbPath + " " + splitRoute[1]
            }
            else {
                title = routeData?.breadcrumbTitle
                path = routeData?.breadcrumbPath
            }

        }

        console.log({ routeObj, pathname });
        return [path, title]
    }, [pathname])

    return <div className='flex flex-col '>
        <div className='font-bold text-gray-600  text-md' >{title}</div>
        <div className='text-xs text-gray-400'>{path}</div>
    </div>
}


const Main = () => {


    const { setIsCollapsed, isCollapsedSidebar, defaultLayout, setLayout } = useLayoutMainContext()
    const { logout, currentUser } = useAuth()
    const [isLoading, setisLoading] = useState(true)
    const navigate = useNavigate()

    const navLinks: link[] = useMemo(() => {

        if (currentUser?.role === ROLES.ADMIN_USER) {
            return [
                {
                    title: "Dashboard",
                    label: "",
                    icon: LayoutGrid,
                    variant: "default",
                    navigationLink: APP_ROUTES.dashboard.fullRoute
                },
                {
                    title: "Projects",
                    label: "",
                    icon: File,
                    variant: "ghost",
                    navigationLink: APP_ROUTES.projects.subRoutes.create.fullRoute,
                    subRoutes: [
                        {
                            title: "All Projects",
                            label: "",
                            icon: Home,
                            variant: "default",
                            navigationLink: APP_ROUTES.projects.subRoutes.all.fullRoute,
                        },
                        {
                            title: "Create",
                            label: "",
                            icon: Home,
                            variant: "default",
                            navigationLink: APP_ROUTES.projects.subRoutes.create.fullRoute,
                        },
                    ]
                },
                {
                    title: "Sales Executives",
                    label: "",
                    icon: File,
                    variant: "ghost",
                    navigationLink: APP_ROUTES.sales_executive.fullRoute,
                    subRoutes: [
                        {
                            title: "All Sales Executives",
                            label: "",
                            icon: Home,
                            variant: "default",
                            navigationLink: APP_ROUTES.sales_executive.subRoutes.users.subRoutes.all.fullRoute,
                        },
                        {
                            title: "All Projects",
                            label: "",
                            icon: Home,
                            variant: "default",
                            navigationLink: APP_ROUTES.sales_executive.subRoutes.projects.subRoutes.all.fullRoute,
                        },
                    ]
                },
                {
                    title: "EPC Contractors",
                    label: "",
                    icon: undefined,
                    variant: "default",
                    navigationLink: APP_ROUTES.epc_contractors.fullRoute
                },
                {
                    title: "Manufacturers",
                    label: "",
                    icon: undefined,
                    variant: "default",
                    navigationLink: APP_ROUTES.manufacturers.fullRoute
                },
                {
                    title: "Investors",
                    label: "",
                    icon: File,
                    variant: "ghost",
                    navigationLink: APP_ROUTES.investors.fullRoute,
                    subRoutes: [
                        {
                            title: "All Investors",
                            label: "",
                            icon: Home,
                            variant: "default",
                            navigationLink: APP_ROUTES.investors.subRoutes.all.fullRoute,
                        },
                    ]
                },
            ]
        }
        else if (currentUser?.role === ROLES.EPC_CONTRACTOR) {
            return [
                {
                    title: "Dashboard",
                    label: "",
                    icon: LayoutGrid,
                    variant: "default",
                    navigationLink: EPC_CONTRACTORS_ROUTES.dashboard.fullRoute
                },
                {
                    title: "Projects",
                    label: "",
                    icon: File,
                    variant: "ghost",
                    navigationLink: EPC_CONTRACTORS_ROUTES.projects.subRoutes.create.fullRoute,
                    subRoutes: [
                        {
                            title: "All Projects",
                            label: "",
                            icon: Home,
                            variant: "default",
                            navigationLink: EPC_CONTRACTORS_ROUTES.projects.subRoutes.all.fullRoute,
                        },
                        // {
                        //     title: "Create",
                        //     label: "",
                        //     icon: Home,
                        //     variant: "default",
                        //     navigationLink: APP_ROUTES.projects.subRoutes.create.fullRoute,
                        // },
                    ]
                },
                {
                    title: "Sales Executives",
                    label: "",
                    icon: File,
                    variant: "ghost",
                    navigationLink: EPC_CONTRACTORS_ROUTES.sales_executive.fullRoute,
                    subRoutes: [
                        {
                            title: "All Sales Executives",
                            label: "",
                            icon: Home,
                            variant: "default",
                            navigationLink: EPC_CONTRACTORS_ROUTES.sales_executive.subRoutes.all.fullRoute,
                        },

                    ]
                },

            ]
        }
        else if (currentUser?.role === ROLES.MANUFACTURER) {
            return [
                {
                    title: "Dashboard",
                    label: "",
                    icon: LayoutGrid,
                    variant: "default",
                    navigationLink: MANUFACTURER_ROUTES.dashboard.fullRoute
                },
                {
                    title: "Projects",
                    label: "",
                    icon: File,
                    variant: "ghost",
                    navigationLink: MANUFACTURER_ROUTES.projects.subRoutes.create.fullRoute,
                    subRoutes: [
                        {
                            title: "All Projects",
                            label: "",
                            icon: Home,
                            variant: "default",
                            navigationLink: MANUFACTURER_ROUTES.projects.subRoutes.all.fullRoute,
                        },
                        // {
                        //     title: "Create",
                        //     label: "",
                        //     icon: Home,
                        //     variant: "default",
                        //     navigationLink: APP_ROUTES.projects.subRoutes.create.fullRoute,
                        // },
                    ]
                },
                {
                    title: "Sales Executives",
                    label: "",
                    icon: File,
                    variant: "ghost",
                    navigationLink: MANUFACTURER_ROUTES.sales_executive.fullRoute,
                    subRoutes: [
                        {
                            title: "All Sales Executives",
                            label: "",
                            icon: Home,
                            variant: "default",
                            navigationLink: MANUFACTURER_ROUTES.sales_executive.subRoutes.all.fullRoute,
                        },

                    ]
                },

            ]
        }

        return [
            {
                title: "Dashboard",
                label: "",
                icon: Home,
                variant: "default",
                navigationLink: APP_ROUTES.dashboard.fullRoute
            },
            {
                title: "Projects",
                label: "",
                icon: File,
                variant: "ghost",
                navigationLink: APP_ROUTES.projects.subRoutes.create.fullRoute,
                subRoutes: [
                    {
                        title: "All Projects",
                        label: "",
                        icon: Home,
                        variant: "default",
                        navigationLink: APP_ROUTES.projects.subRoutes.all.fullRoute,
                    },
                ]
            }
        ]

    }, [currentUser, ROLES])

    useEffect(() => {
      if (currentUser?.role == ROLES.EPC_CONTRACTOR && currentUser?.id) {
        handleValidateUserProfile()
      }
      return () => { }
    }, [currentUser])
  
    const handleValidateUserProfile = catchAsync(async () => {
      setisLoading(true)
      return currentUser?.id && await epcViewProfileByIDApiRequest(currentUser?.id)
    }, (result: any) => {
      if (IS_SUCCESS_RESPONSE(result)) {
        if (!result.data) {
          navigate("/" + EPC_CONTRACTORS_ROUTES.profile.subRoutes.create.fullRoute)
        }
      }
      setisLoading(false)
    }, () => {
      setisLoading(false)
    })


    return (
        <div>
            <TooltipProvider delayDuration={0}>
                <ResizablePanelGroup
                    direction="horizontal"
                    onLayout={(sizes: number[]) => {
                        document.cookie = `react-resizable-panels:layout:mail=${JSON.stringify(
                            sizes
                        )}`
                    }}
                    className="items-stretch"
                    style={{ height: '98vh' }}
                >
                    <ResizablePanel
                        defaultSize={defaultLayout}
                        collapsedSize={4}
                        // collapsible={true}
                        maxSize={15}
                        minSize={15}
                        onCollapse={() => {
                            setIsCollapsed(true)
                            document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                                true
                            )}`
                        }}
                        onResize={() => {
                            setIsCollapsed(false)
                            document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                                false
                            )}`
                        }}
                        className={cn(
                            isCollapsedSidebar &&
                            "min-w-[50px] transition-all duration-300 ease-in-out"
                        )}
                    >
                        <div className='grid grid-rows-7 gap-4 auto-rows-max h-full w-full'>
                            <div className='h-full row-span-6'>
                                <div
                                    className={cn(
                                        "flex h-[52px] ",
                                        isCollapsedSidebar ? "h-[52px]" : "px-2"
                                    )}
                                >
                                    {/* Admin LOGO */}
                                </div>
                                <Separator />
                                <Nav
                                    isCollapsed={isCollapsedSidebar}
                                    links={navLinks}
                                />
                            </div>
                            <div className={cn('h-full p-4 space-y-2', { "flex flex-col items-center": isCollapsedSidebar })}>

                                <div className='w-full'>
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger className='w-full'><Button onClick={logout} className='w-full' variant={'outline'}>{isCollapsedSidebar ? <LogOut size={16} /> : <div className='flex space-x-2' > <span>Logout</span> <LogOut size={16} /> </div>}</Button></TooltipTrigger>
                                            <TooltipContent>
                                                <p>Logout</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            </div>
                        </div>

                    </ResizablePanel>
                    <ResizableHandle />
                    <ResizablePanel defaultSize={defaultLayout} minSize={30}>
                        <div className="flex justify-between items-center px-4 py-2">
                            <ReanderBreadcrumb />
                            <ProfileComp />

                        </div>
                        <Separator />
                        <div className='h-full w-full'>
                            {/* <ScrollArea className='max-h-[90vh] max-w-[70vw] '> */}
                                <Outlet />
                            {/* </ScrollArea> */}
                        </div>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </TooltipProvider>
        </div>
    )
}

const AppLayoutMain = (props: Props) => {
    return <LayoutMainContextProvider >
        <Main />
    </LayoutMainContextProvider>

}

export default AppLayoutMain