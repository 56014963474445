import { ADDRESS_REGEX, AUTHORIZATION_PARTNER_OPTIONS, EMAIL_REGEX, FormField, IMAGE_DOCUMENT_VALIDATION_SCHEMA, NAME_REGEX, NUMBER_REGEX, SPECIALIZATION_OPTIONS, YES_NO_OPTIONS } from "src/helpers";
import CustomTextIcon from "src/shadcn/components/custom/CustomeSymbols/CustomTextIcon";
import * as Yup from "yup"

export interface IMANUFACTURER_PROFILE {
    manufacturer_name: string,
    phoneNumber: string,
    manufacturerEmail: string,
    location: string,
    address: string,
    city:string,
    pincode: string,
    authorization_partner:string,
    specialization:string,
    is_otc_verified:string
    noOfYearsExperiance: string,
    review: string,
    products: IMANUFACTURER_PRODUCTS[],
    product_efficiency:string,
    companyAbouteUs: string,
    companyLogo: string | File | undefined,
    _id?:string,
    manufacturer_id?:string
}

export interface IMANUFACTURER_PRODUCTS {
    formId?: number | undefined,
    productImage: string | File,
    productName: string,
    productDescription: string,
    _id?:string
}



export const INIT_MANUFACTURER_PRODUCTS = {
    formId: 1,
    productImage: "",
    productName: "",
    productDescription: ""
}

export const INIT_MANUFACTURER_PROFILE = {
    manufacturer_name: "",
    phoneNumber: "",
    manufacturerEmail: "",
    location: "",
    address: "",
    city:"",
    pincode: "",
    authorization_partner:"",
    specialization:"",
    is_otc_verified:"",
    noOfYearsExperiance: "",
    review: "",
    product_efficiency:"",
    companyAbouteUs: "",
    companyLogo: "",
    products: [],
}
  


export const MANUFACTURER_PROFILE_FORM_FIELDS: FormField[] = [
    {
        label: 'Manufacturer Name',
        dataKey: 'manufacturer_name',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Name',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Phone Number',
        dataKey: 'phoneNumber',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Phone Number',
            regex: NUMBER_REGEX,
            maxLength: 10,
            required: true
        },
    },
    {
        label: 'Manufacturer Email',
        dataKey: 'manufacturerEmail',
        componentType: 'input',
        componentProps: {
            type: "email",
            placeholder: 'Email',
            maxLength: 150,
            required: true,
            regex: EMAIL_REGEX,
            // readonly:true
        },
    },
    {
        label: 'Location',
        dataKey: 'location',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Location',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'City',
        dataKey: 'city',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'city',
            maxLength: 250,
            required: true,
            regex: ADDRESS_REGEX
        },
    },
    {
        label: 'Address',
        dataKey: 'address',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Address',
            maxLength: 250,
            required: true,
            regex: ADDRESS_REGEX
        },
    },
    {
        label: 'Pincode',
        dataKey: 'pincode',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            placeholder: 'Pincode',
            maxLength: 6,
            required: true
        },
    },
    {
        label: 'Panel Efficiency (%)',
        dataKey: 'product_efficiency',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            placeholder: 'Panel Efficiency',
            maxLength: 50,
            numberFormatted: true,
            required: true,
            // inputSuffixIcon: <CustomTextIcon value="%" />
        },
    },
    {
        label: 'No. of Years Experience',
        dataKey: 'noOfYearsExperiance',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            placeholder: 'No. of Years Experience',
            maxLength: 50,
            numberFormatted: true,
            required: true
        },
    },
    {
        label: 'No. of Reviews',
        dataKey: 'review',
        componentType: 'options',
        componentProps: {
            options: [...new Array(5)].map((_, indx: number) => {
                return {
                    label: (indx + 1).toString(),
                    value: (indx + 1).toString()
                }
            })
        },
        
    },
    {
        label: 'Authorization Partner',
        dataKey: 'authorization_partner',
        componentType: 'options',
        componentProps: {
            options:AUTHORIZATION_PARTNER_OPTIONS
        },
    },
    {
        label: 'OCT-Verified',
        dataKey: 'is_otc_verified',
        componentType: 'options',
        componentProps: {
            options:YES_NO_OPTIONS
        },
    },
    {
        label: 'Specialization',
        dataKey: 'specialization',
        componentType: 'options',
        componentProps: {
            options:SPECIALIZATION_OPTIONS,
            optionsSearchable:true,
            required: true
        },
    },
    {
        label: 'About us',
        dataKey: 'companyAbouteUs',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Write about yourself',
            required: true
        },
    },
    {
        label: 'Company Logo',
        dataKey: 'companyLogo',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Select files',
            required: true
        },
    },

];


export const EPC_SERVICES_FORM_FIELDS: FormField[] = [
    {
        label: 'Product Image',
        dataKey: 'productImage',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Select image',
            required: true
        },
    },
    {
        label: 'Product Name',
        dataKey: 'productName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Product Name',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Product Description',
        dataKey: 'productDescription',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Product Description',
            required: true,

        },
    },
];


export const epcContractorProfileSchema = Yup.object().shape({
    manufacturer_name: Yup.string().required('Manufacturer name is required'),
    phoneNumber: Yup.string().length(10,"Should be 10 digits only").required('Phone number is required'),
    manufacturerEmail: Yup.string().email('Invalid email').required('Email is required'),
    location: Yup.string().required('Location is required'),
    address: Yup.string().required('Address is required'),
    pincode: Yup.string().length(6,"Should be 6 digits only").required('Pincode is required'),
    authorization_partner: Yup.string().required('Authorization partner is required'),
    specialization: Yup.string().required('Specialization is required'),
    is_otc_verified: Yup.string().required('OTC verification is required'),
    noOfYearsExperiance: Yup.number().required('Number of years of experience is required'),
    review: Yup.string().required('Review is required'),
    product_efficiency: Yup.string().required('Product efficiency is required'),
    companyAbouteUs: Yup.string().required('Company about us is required'),
    companyLogo: IMAGE_DOCUMENT_VALIDATION_SCHEMA,
});


export const epcContractorServicesSchema = Yup.array().of(
    Yup.object().shape({
        productImage: IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Product image required"),
        productName: Yup.string().required('Product name is required'),
        productDescription: Yup.string().required('Product description is required'),
    })
)

export const epcContractorSingleServicesSchema =
    Yup.object().shape({
        productImage: IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Product image required"),
        productName: Yup.string().required('Product name is required'),
        productDescription: Yup.string().required('Product description is required'),
    })


