import axios, { AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/Auth";
import { ILoginResponse } from "./modules";

export function loginRequestApi({ email, password }: any): Promise<ILoginResponse> {
    return axiosInstanceWithoutAuth.post(API.URL_LOGIN, { email, password }).then((d: AxiosResponse) => d.data);
}

export function registrationRequestApi({ email, password, role }: any): Promise<any> {
    return axiosInstanceWithoutAuth.post(API.URL_REGISTRATION, { email, password, role }).then((d: AxiosResponse) => d.data);
}

export function forgotPasswordRequestApi({ email }: any): Promise<any> {
    return axiosInstanceWithoutAuth.post(API.URL_FORGOT_PASSWORD, { email }).then((d: AxiosResponse) => d.data);
}

export function verifyOTPRequestApi({ email, otp }: any): Promise<any> {
    return axiosInstanceWithoutAuth.post(API.URL_VERIFY_OTP, { email, otp }).then((d: AxiosResponse) => d.data);
}

export function resendOTPRequestApi({ email }: any): Promise<any> {
    return axiosInstanceWithoutAuth.post(API.URL_RESEND_OTP, { email }).then((d: AxiosResponse) => d.data);
}


export function resetPasswordRequestApi(data: any): Promise<any> {
    return axiosInstanceWithoutAuth.put(API.URL_RESET_PASSWORD, { ...data }).then((d: AxiosResponse) => d.data);
}

export function createUserRequestApi(data: any): Promise<any> {
    return axiosInstanceWithoutAuth.post(API.URL_CREATE_USER, { ...data }).then((d: AxiosResponse) => d.data);
}
