import React, { useMemo } from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '../ui'

type Props = {}

const DocumentViwer = ({ url, title, contentType }: { url: string, title?: string, contentType?: string }) => {

    const extention = useMemo(() => {
        return url.slice((url.lastIndexOf(".") - 1 >>> 0) + 2);
    }, [url])


    // console.log({ extention });

    return (
        // url ?: null
        <div>
            {
                url && ["jpeg", "jpg", "png", "jfif"].includes(extention) ?
                    <PhotoProvider>
                        <PhotoView src={url}>
                            <div className='text-blue-500 text-sm font-semibold underline underline-offset-2 cursor-pointer'>{title ? title : "View"}</div>
                        </PhotoView>
                    </PhotoProvider>
                    : null
            }
            {url && extention == "mp4" ? <Dialog>
                <DialogTrigger>
                    <div className='text-blue-500  text-sm  font-semibold underline underline-offset-2 cursor-pointer'>{title ? title : "View"}</div>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogDescription>
                            <video autoPlay controls={true} className="w-full">
                                <source src={url} type="video/mp4" />
                            </video>
                        </DialogDescription>
                    </DialogHeader>
                </DialogContent>
            </Dialog> : null}
            { url  &&  (!["jpeg", "jpg", "png", "jfif", "mp4"].includes(extention))? <>
                {
                    contentType == "image" ? <PhotoProvider>
                        <PhotoView src={url}>
                            <div className='text-blue-500 font-semibold underline underline-offset-2 cursor-pointer'>{title ? title : "View"}</div>
                        </PhotoView>
                    </PhotoProvider> : null
                }
                {
                    contentType == "video" ? <Dialog>
                        <DialogTrigger>
                            <div className='text-blue-500 font-semibold underline underline-offset-2 cursor-pointer'>{title ? title : "View"}</div>
                        </DialogTrigger>
                        <DialogContent>
                            <DialogHeader>
                                <DialogDescription>
                                    <video autoPlay controls={true} className="w-full">
                                        <source src={url} type="video/mp4" />
                                    </video>
                                </DialogDescription>
                            </DialogHeader>
                        </DialogContent>
                    </Dialog> : null
                }
            </> : null}
        </div>




    )
}

export default DocumentViwer