import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { IRoutes, ROUTES } from 'src/helpers/routes'
import AppLayoutMain from 'src/modules/Layouts/App/AppLayoutMain'
import DashboardMain from 'src/pages/Dashboard/DashboardMain'
import ProfileMain from 'src/pages/Profile'
import ProjectsRoute from 'src/pages/Projects/ProjectsRoute'
import SalesExecutiveRoutes from 'src/pages/SalesExecutives/SalesExecutiveRoutes'
import { APP_ROUTES } from './modules'
import InvestorsRoute from 'src/pages/investors/InvestorsRoute'
import AllEpcContractorsMain from 'src/pages/EpcContractors/AllEpcContractors/AllEpcContractorsMain'
import EpcContractorDetail from 'src/modules/EpcContractors/EpcProfile/EpcContractorDetail'
import AdminManufacturerRoutes from 'src/pages/Manufacturer/AdminManufacturerRoutes'
import EpcContractorCreateMain from 'src/pages/EpcContractors/Profile/EpcContractorCreateMain'
import EpcContractorUpdateMain from 'src/pages/EpcContractors/Profile/EpcContractorUpdateMain'
import EpcContractorUpdateMainAdmin from 'src/pages/EpcContractors/Profile/EpcContractorUpdateMainAdmin'

type Props = {}

type RenderRoutesProps = {
  route: IRoutes,
  role: string
}

const RenderRoutes = ({ route, role }: RenderRoutesProps) => {

  const Component = lazy(() => import(route.componentPath));

  return <>
    {
      (
        <Route
          key={"route-" + route.fullPath}
          path={route.routePath}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Component />
            </Suspense>
          }
        />
      )
    }
  </>

  if (route.roles.includes(role)) {

    // if (route.subRoutes && route.subRoutes.length > 0) {
    //   return <Route path={route.routePath} element={
    //     <Routes>
    //       <Route element={<Outlet />}>
    //         <Route index element={<Navigate to={route.indexRoute || "/"} />} />
    //         {
    //           route.subRoutes.map((d: IRoutes) => {
    //             return <RenderRoutes route={d} role={role} />
    //           })
    //         }
    //       </Route>
    //     </Routes>
    //   } />
    // }
    // else {
    return <Route
      key={"route-" + route.fullPath}
      path={route.routePath}
      element={
        <Suspense fallback={<div>Loading...</div>}>
          <Component />
        </Suspense>
      }
    />
    // }
  }
  else return null
};


const AppRoutes = (props: Props) => {

  const userRole = "admin"
  return (
    <Routes>
      <Route element={<AppLayoutMain />} >
        <Route index element={<Navigate to={ROUTES.dashboard.path} />} />
        <Route path={APP_ROUTES.dashboard.route} element={<DashboardMain />} />
        <Route path={APP_ROUTES.epc_contractors.route} element={<AllEpcContractorsMain />} />
        <Route path={APP_ROUTES.epc_contractors.subRoutes.edit.fullRoute + "/:id"} element={<EpcContractorUpdateMainAdmin />} />
        <Route path={APP_ROUTES.epc_contractors.subRoutes.view.fullRoute + "/:id"} element={<EpcContractorDetail />} />
        <Route path={APP_ROUTES.epc_contractors.subRoutes.create.fullRoute} element={<EpcContractorCreateMain />} />
        <Route path={APP_ROUTES.epc_contractors.subRoutes.edit.fullRoute} element={<EpcContractorUpdateMain />} />
        <Route path={APP_ROUTES.projects.route + "/*"} element={<ProjectsRoute />} />
        <Route path={APP_ROUTES.sales_executive.route + "/*"} element={<SalesExecutiveRoutes />} />
        <Route path={APP_ROUTES.investors.route + "/*"} element={<InvestorsRoute />} />
        <Route path={APP_ROUTES.profile.route} element={<ProfileMain />} />
        <Route path={APP_ROUTES.manufacturers.route + "/*"} element={<AdminManufacturerRoutes />} />
        {/* {
          Object.entries(ROUTES).map(([key, value], index) => {
            return <RenderRoutes route={value as any} role={userRole} />
          })
        } */}
      </Route>
    </Routes>
  )
}

export default AppRoutes