import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES, MAIN_ROUTES } from "./modules";
import LoginMain from "src/pages/Auth/LoginMain";
import AuthLayoutMain from "src/modules/Layouts/Auth/AuthLayoutMain";
import AuthRoutes from "./AuthRoutes";
import AppRoutes from "./AppRoutes";
import { useAuth } from "src/modules/Auth/Core";
import { ROLES } from "src/helpers";
import EPSRoutes from "./EPSRoutes";

const { PUBLIC_URL } = process.env;

type Props = {};

const MainRoutes = (props: Props) => {

  const { currentUser } = useAuth()
  console.log({ currentUser });

  // const currentUser =  true


  return (
    <BrowserRouter>
      <Routes>
        {
          currentUser ?
            <>
              {
                currentUser.role == ROLES.ADMIN_USER ? <>
                  <Route path={"/*"} element={<AppRoutes />} />
                  <Route path='*' element={<Navigate to={APP_ROUTES.dashboard.route} />} />
                </> : null
              }
              {
                currentUser.role == ROLES.EPC_CONTRACTOR ? <>
                  <Route path={"/*"} element={<EPSRoutes />} />
                  <Route path='*' element={<Navigate to={APP_ROUTES.dashboard.route} />} />
                </> : null
              }
            </>
            :
            <>
              <Route path={MAIN_ROUTES.auth + "/*"} element={<AuthRoutes />} />
              <Route path='*' element={<Navigate to={MAIN_ROUTES.auth_login} />} />
            </>
        }
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
